// State
export const state = () => ({
	loading: false,
	manualAdj: [],
	adjustment: {},
	cancelToken: {}
})


// Actions
export const actions = {
	// get manual adjustments
	async manualAdj({ commit, state }, params) {
		try {
			commit('setCancelToken', { manualAdj: this.$axios.CancelToken.source() })
			if (!params.purpose) delete params.purpose
			commit('loading', true)
			await this.$axios
				.get(`/finances/manual-adjustments`, {
					params,
					cancelToken: state.cancelToken.manualAdj.token
				})
				.then((response) => {
					commit('setManual', response.data)
					commit('loading', false)
				}).catch(() => {
					commit('loading', false)
				})
		} catch (e) {
			commit('loading', false)
		}
	},
	// Create manual
	async create({ commit, rootGetters }, data) {
		commit('loading', true)
		const response = await this.$axios.post(`/finances/manual-adjustments`, data).then(() => {
			commit('loading', false)
			return true
		}).catch(() => {
			commit('loading', false)
			return false
		})
		return response
	},
}


// Mutations
export const mutations = {
	loading: (state, type) => {
		state.loading = type
	},
	setManual: (state, data) => {
		state.manualAdj = data
	},
	setAdjustment: (state, data) => {
		state.manualAdj = data
	},
	setCancelToken: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	}
}